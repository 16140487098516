import React from "react"
import Select from 'react-select'
import { HeaderView , GetErrorMessage, GetDynamicField, GetGenerateIdDynamicField } from '../helpers/common'
import { Logout} from "../helpers/paraHelpers"

function brandFormComponent(props) {
  return (
    <div className="App">
      <HeaderView goBack={ () => props.history.goBack() } onClickLogOut={ () =>  Logout() } isLogin ={props.isValidUser}/>
      <div className="container">
        {(props.formError) ? <GetErrorMessage {...props} /> : '' }        
        <form className="configForm" onSubmit={props.handleSubmit} >
          <div className="form-group row">
            <div className="form-group col-md-4">
              <label>
                Name of Brand *
              </label>
              <div>
                <input
                  onChange={props.handleChange}
                  className="form-control"
                  name="brandName"
                  placeholder="Brand Name"
                  value={props.brandName}
                />
              </div>
            </div>

            <div className="form-group col-md-4">
              <label>
                Registration Type *
              </label>
              <div>
                <select
                  onChange={props.handleChange}
                  className="form-control"
                  name="registrationType"
                  value={props.registrationType}
                >
                  <option value="">-- Please Choose Registration Type --</option>
                  <option value="email"> Email </option>
                  <option value="externalId"> ExternalId </option>
                </select>
              </div>
            </div>        

            <div className="form-group col-md-4">
              <label>
                Generate Id
              </label>
              <div>
                <select
                  onChange={props.handleChange}
                  className="form-control"
                  name="generateId"
                  value={props.generateId}
                >
                  <option value="">-- Please Choose Generate Id --</option>
                  <option value="list"> List </option>
                  <option value="random"> Random </option>
                </select>
              </div>
            </div>
          </div>
          <GetGenerateIdDynamicField {...props} />
          <div className="form-group row">
            <div className="form-group col-md-4">
              <label>
                App Name
              </label>
              <input
                onChange={props.handleChange}
                className="form-control"
                name="appName"
                placeholder="App Name"
                value={props.appName}
              />
            </div>

            <div className="form-group col-md-4">
              <label>
                Environment
              </label>
              <select
                onChange={props.handleChange}
                className="form-control"
                name="environment"
                value={props.environment}
              >
                <option value="">-- Please Choose Environment --</option>
                <option value="demo"> Demo </option>
                <option value="pro"> Production </option>
                <option value="staging"> Staging </option>
              </select>
            </div>

            <div className="form-group col-md-4">
              <label>
                Region
              </label>
              <select
                onChange={props.handleChange}
                className="form-control"
                name="region"
                value={props.region}
              >
                <option value="">-- Please Choose Region--</option>
                <option value="apse1"> APSE1 </option>
                <option value="apse2"> APSE2 </option>
                <option value="euw1"> EUW1 </option>
                <option value="euw2"> EUW2 </option>
                <option value="use2"> USE2 </option>
              </select>
            </div>

          </div>
          <div className="form-group">
            <label>
              Override URL (Optional)
            </label>
            <div>
              <input
                onChange={props.handleChange}
                className="form-control"
                name="overrideURL"
                placeholder=""
                defaultValue={props.overrideURL}
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              className="col-sm-3 col-form-label" >
              Parameters
            </label>
            <div className="col-sm-9">
              <textarea
                onChange={props.handleChange}
                className="form-control"
                name="parameters"
                placeholder="Parameters"
                rows="10"
                defaultValue={props.parameters}
              />
            </div>
          </div>

          <GetDynamicField {...props} targetedField='parameters' />
          

          <div className="form-group">
            <label>
              HMAC Key
            </label>
            <div>
              <input
                onChange={props.handleChange}
                className="form-control"
                name="hmacKey"
                placeholder="HMAC Key"
                value={props.hmacKey}
              />
            </div>
          </div>
          
          <div className="form-group">
            <Select
              isMulti
              name="hmacParameters"
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={ props.handleSelectChange }
              value={ props.selectedOption }
              onChange={ props.handleSelectChange }
              options={ (props.parameters).split('\n').map(function(val){
                return val ? { 'value':val, 'label':val } : ''
              }).filter(v => v.value && v.value !== '') }
            />
          </div>

          <div className="form-group">
            <label>
              HMAC Signature Parameter
            </label>
            <div>
              <input
                onChange={props.handleChange}
                className="form-control"
                name="hmacSignatureParameter"
                placeholder="signature"
                value={props.hmacSignatureParameter}
              />
            </div>
          </div>

          <div className="form-group">
            <label>
              HMAC N-Once Parameter
            </label>
            <div>
              <input
                onChange={props.handleChange}
                className="form-control"
                name="hmacNonceParameter"
                placeholder="nonce"
                value={props.hmacNonceParameter}
              />
            </div>
          </div>
          
          <div>
            <hr className="show-line" />
          </div>

          <div className="form-group">
            <input 
            type="submit"
            className="btn btn-success col-md-6"
            value="Submit" />
          </div>

        </form>
      </div>
    </div>
  );
}


export default brandFormComponent
