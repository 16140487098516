import React from "react"
import BrandFormContainer from "./brandFormContainer"
import LoginFormContainer from "./loginFormContainer"
import HomeContainer  from "./homeContainer"
import LaunchContainer  from "./launchContainer"
import TestContainer from "./testFormContainer"
import ServiceContainer from "./serviceFormContainer"
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css"

import {
    HashRouter as Router,
    Switch,
    Route
  } from "react-router-dom"

function App() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginFormContainer />
          </Route>
          <Route exact path="/login">
            <LoginFormContainer />
          </Route>
          <Route exact path="/home">
            <HomeContainer/>
          </Route> 
          <Route path="/config/:id">
            <BrandFormContainer />
          </Route>
          <Route path="/config">
            <BrandFormContainer />
          </Route>                
          <Route path="/launch/:id">
            <LaunchContainer/>
          </Route>
          <Route path="/test/:id">
            <TestContainer/>
          </Route>
          <Route path="/service/:id">
            <ServiceContainer/>
          </Route> 
        </Switch>
      </Router>
      )
}

export default App
