/* global localStorage */
import React from "react"
import logo from '../3rlogo.png'
import qs from "qs"
import { FaSignOutAlt, FaArrowLeft } from 'react-icons/fa'
import { Link } from "react-router-dom"
import _ from 'lodash'
import Papa from 'papaparse'
import { editLaunchConfig } from "./paraHelpers"
import { confirmAlert } from 'react-confirm-alert'
import Collapsible from 'react-collapsible';
import hmac from 'hmac-signature'

export function Capitalize(str){
    if(!str){
        return
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
  
function GetFields(props){
    let allValues = props.targetedField !== null ? (props[props.targetedField]).split('\n') : []
    let items =[]
    for (const [index, value] of allValues.entries()) {
        let storedValue =  props[value] ? props[value] : ''
        let key = value+'_'+index
        if(value !== ''){
            items.push(
                <div className="form-group row" key={key}>
                    <label 
                        className="col-sm-3 col-form-label" >
                        {value}
                    </label>
                    <div className="col-sm-9">
                        <input
                        onChange={props.handleChange}
                        className="form-control"
                        name={value}
                        value = {storedValue}
                        />
                    </div>
                </div>
            )
        }    
    }
    return items;
}

export function GetDynamicField(props) {
    if(props.targetedField){
        return (
        <div>
            <div className="values-header">
            <h5>{Capitalize(props.targetedField)} Values</h5>
            </div>
            <GetFields {...props} />
            <hr className="show-line" />
        </div>
        )
    } 
}
  
export function GetGenerateIdDynamicField(props) {
    if (props.generateId === 'list'){
        return (
        <div className="form-group">
            <label>
                List File
            </label>
            <div>
                <input
                onChange={props.handleChange}
                className="form-control"
                name="list"
                placeholder="List File"
                value={props.list}
                />
            </div>
        </div>
        )
    } else {
        return null
    }
}

export function GetErrorMessage(){
    return <div className="alert alert-danger" role="alert">
        Could not submit form please fill all the required field marked with <strong>*</strong>
    </div>
}

export function CreateLaunchConfigList(values) {
    var userData = JSON.parse(localStorage.getItem('userData'))
    var brands = values.list.reduce(function(mem, cur) {
        if(!cur.environment && cur.overrideURL && cur.overrideURL.match(/vocohub/)){
            cur.environment = cur.overrideURL.split(".")[0].replace(/http(s?):\/\//, "").split("-")[0]
        }

        if(!mem[cur.environment]) {
            mem[cur.environment] = {}
        }

        if(!mem[cur.environment][cur.region]) {
            mem[cur.environment][cur.region] = []
        }

        if(userData?.id && userData.id === cur.creatorid) {
            cur.createdByMe = true
        }
        
        mem[cur.environment][cur.region].push(cur)

        return mem
    }, [])
    
    if(userData?.id) {
        Object.keys(brands).map((env) => {
            return Object.keys(brands[env]).map((region) => {
                brands[env][region] = brands[env][region].sort((a, b) => {
                    let orderResult
                    if(!a.createdByMe && b.createdByMe) {
                        orderResult = 1
                    }
                    
                    if(a.createdByMe && !b.createdByMe) {
                        orderResult = -1
                    }
                    
                    if(typeof orderResult === "undefined") orderResult = a.brandName < b.brandName ? -1 : 1
                    
                    return orderResult
                })
            })
        })
    }
    
    return (
        <div className="list-environment list-group col-md-12">
            { 
                Object.keys(brands).map(env => (
                    <Collapsible trigger={env} triggerTagName="div" className="list-group-item list-group-item-action" openedClassName="list-group-item" key={env}>
                        <div className="list-region list-group p-0">
                            {
                                Object.keys(brands[env]).map(region => (
                                    <Collapsible  trigger={region} triggerTagName="div" className="list-group-item list-group-item-action region" openedClassName="list-group-item region" key={region}>
                                        <div className="list-group p-0">
                                            {
                                                brands[env][region].map(item => (
                                                    <div className="row links-wrapper" key={ item.id }>
                                                        <Link className={["list-group-item","list-group-item-action","list"].join(" ") + (item.createdByMe ? " createdByMe" : "")}  to={ '/launch/' + item.id }>
                                                            { Capitalize((item.brandName).trim()) }
                                                        </Link>

                                                        <Link className="btn btn-success m-1" to={
                                                            {
                                                                pathname: '/launch/' + item.id, state: {
                                                                    directLaunch: true
                                                                }
                                                            }
                                                        } >
                                                        Launch
                                                        </Link>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Collapsible>
                                ))
                            }
                        </div>
                    </Collapsible>
                ))
            }            
        </div>
    )
}

export async function GenerateHmacUrl(data, generateType, field) {
    let launchUrl = 'https://'+
    data.environment+
    '-'+
    data.region+
    '-app.vocohub.com/'+
    data.appName+
    '/main/app/' + (data.environment === "demo" ? 'debugindex.html' : 'index.html')

    if (data.overrideURL) {
        launchUrl = data.overrideURL
    }

    if((data.hmacParameters).length === 0){
        return launchUrl
    }
    let queryData = {}
    let signString = ''
    let hmacKey = data.hmacKey
    let hmacParameters = data.hmacParameters
    let userData = JSON.parse(localStorage.getItem('userData'))

    let found = false
    let count = 0
    let usedEmailAndIds = data.usedEmailAndIds || []
    if (generateType === 'list') {       
        let totalCSVCount = 0
        try {
            totalCSVCount = (await getCSVDataCount(data[generateType])).length
        } catch (e) {
            return { error: 'Something went wrong while trying to read the file please check file URL and file access for CORS error' }
        }      
        
        do {

            try {
                userData = await generateRandoOrList(generateType, field, userData, data, count)
            } catch (e) {
                return { error: 'Something went wrong while trying to generate random or read from file' }
            }

            found = _.find(usedEmailAndIds, function (value) {
                return value === userData[field]
            }) ? true : false
            count++
        } while (found === true && totalCSVCount > count)
    
        if (count === totalCSVCount) {
            return { error: 'All ' + field + ' from csv have already been used' }
        }
    } else {
        try {
            userData = await generateRandoOrList(generateType, field, userData, data, count)
        } catch (e) {
            return { error: 'Something went wrong while trying to generate random or read from file' }
        }  
    }

    let params = data.parameters.split(/\r?\n/).map(s => s.trim());

    params.forEach(function (param) {
        let value =  data[param]

        if (typeof value == "string") {
            value = value.replace(/\{\{([^\}]+)\}\}/ig, function (match, capture) {
                return _.get({ "user" : userData }, capture)
            })
        }

        queryData[param] = value
    })


    hmacParameters.forEach(function (field) {
        let value = data[field.value]
        if (typeof value == "string") {
            value = value.replace(/\{\{([^\}]+)\}\}/ig, function (match, capture) {
                return _.get({ "user" : userData }, capture)
            })
        }
        
        if (value !== 'undefined') {
            queryData[field.value] = value
            signString = signString + JSON.stringify(value)              
        }	
    })

    let config= {version: 1, key: hmacKey, fields : hmacParameters.map(f => f.value), debug : true }

    if (data.hmacSignatureParameter) {
        config.signatureParameter = data.hmacSignatureParameter
    }

    if (data.hmacNonceParameter) {
        config.nonceParameter = data.hmacNonceParameter
    }


    console.log(config, queryData)
    let signedParams = await hmac.sign(config, queryData)

    if (data.hmacSignatureParameter) {
        queryData[data.hmacSignatureParameter] = signedParams.signature
    } else {
        queryData.signature = signedParams.signature
    }

    if (data.hmacNonceParameter) {
        queryData[data.hmacNonceParameter] = signedParams.nonce
    } else {
        queryData.nonce = signedParams.nonce
    }

    launchUrl = (launchUrl.match(/\?/) ? launchUrl + '&' : launchUrl + '?') + qs.stringify(queryData)

    usedEmailAndIds.push(userData[field])
    data['usedEmailAndIds'] = usedEmailAndIds
    //Update launch config with new used email or externalId
    editLaunchConfig(data.id, data)
    return launchUrl
}



export async function GenerateTestHmacUrl(data, generateType, type, field) {
    let queryData = {}
    queryData['testDomain'] = field.domain.trim()
    queryData['testFolder'] = field.folder.trim()
    queryData['tests'] = field.tests.trim()
    let testUrl = 'https://'+
    data.environment+
    '-'+
    data.region+
    '-app.vocohub.com/'+
    data.appName+
    '/main/app/debugindex.html'
    
    if(field.register){
        testUrl = (testUrl.match(/\?/) ? testUrl + '&' : testUrl + '?') + qs.stringify(queryData)
        return testUrl
    }

    let signString = ''
    let hmacKey = data.hmacKey
    let hmacParameters = data.hmacParameters
    let userData = JSON.parse(localStorage.getItem('userData'))
    
    let found = false
    let count = 0
    let usedEmailAndIds = data.usedEmailAndIds || []
    if (generateType === 'list') {       
        let totalCSVCount = 0
        try {
            totalCSVCount = (await getCSVDataCount(data[generateType])).length
        } catch (e) {
            return { error: 'Something went wrong while trying to read the file please check file URL and file access for CORS error' }
        }  
        do {
            try {
                userData = await generateRandoOrList(generateType, type, userData, data, count)
            } catch (e) {
                return { error: 'Something went wrong while trying to generate random or read from file' }
            }               
            found = _.find(usedEmailAndIds, function (value) {
                return value === userData[type]
            }) ? true : false
            count++
        } while (found === true && totalCSVCount > count)
    
        if (count === totalCSVCount) {
            return { error: 'All ' + type + ' from csv have already been used' }
        }
    } else {
        try {
            userData = await generateRandoOrList(generateType, type, userData, data, count)
        } catch (e) {
            return { error: 'Something went wrong while trying to generate random or read from file' }
        }
    }

    hmacParameters.forEach(function (field) {
        let value = data[field.value]
        if (typeof value == "string") {
            value = value.replace(/\{\{([^\}]+)\}\}/ig, function (match, capture) {
                return _.get({ "user" : userData }, capture)
            })
        }
        
        if (value !== 'undefined') {
            queryData[field.value] = value
            signString = signString + JSON.stringify(value)              
        } 
    })
    let config= {version: 1, key: hmacKey}
    let signedParams = await hmac.sign(config, queryData)
    Object.assign(queryData, signedParams)
    // sha.update(signString)
    // sha.update(nonce)
    // queryData['signature'] = sha.getHMAC('HEX')
    // queryData['nonce'] = nonce    
    testUrl = (testUrl.match(/\?/) ? testUrl + '&' : testUrl + '?') + qs.stringify(queryData)    
    usedEmailAndIds.push(userData[type])
    data['usedEmailAndIds'] = usedEmailAndIds
    //Update launch config with new used email or externalId
    editLaunchConfig(data.id, data)
    return testUrl

}

function getCSVData(fileUrl, index) {
    return new Promise(function (resolve, reject) {
        Papa.parse(fileUrl, {
            download: true,
            header: true,
            complete: function (results) {
                let randomRow = results.data[index]
                resolve (randomRow)
            },
            error: function (error) {
                reject(error)
            }
        })
    })
}

function getCSVDataCount(fileUrl) {
    return new Promise(function (resolve, reject) {
        Papa.parse(fileUrl, {
            download: true,
            header: true,
            complete: function (results) {
                let randomRow = results.data
                resolve (randomRow)
            },
            error: function (error) {
                reject(error)
            }
        })
    })
}

async function generateRandoOrList(generateType, field, userData, launchData, index) {
    let csvData = null
    if (generateType === 'random' && field === 'email' && userData[field]) {
        userData.email = randomizeInteger() + '@testing.com'
    } else if (generateType === 'random' && field === 'externalId') {
        userData[field] = (randomizeInteger()).toString()
    } else if (generateType === 'list' && field === 'email' && userData[field]) {
        let urlField = launchData[generateType]
        try {
            csvData = await getCSVData(urlField, index)
        } catch (e) {
            return { error: 'Something went wrong while trying to read the file please check file URL and file access for CORS error' }
        } 
        
        userData[field] = csvData[field]
    } else if (generateType === 'list' && field === 'externalId') {
        let urlField = launchData[generateType]
        try {
            csvData = await getCSVData(urlField, index)
        } catch (e) {
            return { error: 'Something went wrong while trying to read the file please check file URL and file access for CORS error' }
        } 
        userData[field] = csvData[field]
    }
    return userData
}

function randomizeInteger(min, max) {
    if(max == null) {
      max = (min == null ? Number.MAX_SAFE_INTEGER : min);
        min = 0
  }

  min = Math.ceil(min)
  max = Math.floor(max)

    if(min > max - 1) {
      throw new Error("Incorrect arguments.")
  }

  return min + Math.floor((max - min) * Math.random())
}

function makeid(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
}

function GetLogOut(props) {
    if (props.isLogin) {
        return (
            <div className="logout-wraapper">
                <i className="logout" onClick= { props.onClickLogOut}>
                    <FaSignOutAlt />
                </i>
            </div>
        )
    } 
    else {
        return null
    }
}

function GoBack(props) {
    if (props.goBack) {
        return (
            <div className="back-wraapper"  onClick={ props.goBack }>
                <i className="back">
                    <FaArrowLeft />
                </i>
            </div>
        )
    } 
    else {
        return null
    }
}

export function HeaderView(props) {    
    return (
        <header>
            <GoBack {...props}/>
            <a href="/#/home">
                <div>
                    <img src={logo} className="App-logo" alt="logo" />
                </div>            
            </a>
            <GetLogOut { ...props }/>
        </header>
    )
}

export function errorMessage(errorMessage) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='alert-delete'>
                        <h1>Sorry !</h1>
                        <p>{ errorMessage }</p>
                        <button onClick={onClose}>Close</button>
                    </div>
                )
            }
        })    
}

export default HeaderView
