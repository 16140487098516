import React from "react"
import { GetErrorMessage, HeaderView, Capitalize } from '../helpers/common'
import { Logout } from '../helpers/paraHelpers'

function TestsFormComponent(props) {
  return (
    <div className="App">
        <HeaderView goBack={ () => props.history.goBack() } onClickLogOut={ () =>  Logout() } isLogin ={props.isValidUser}/>
        <div className="container">   
            {(props.formError) ? <GetErrorMessage {...props} /> : '' }            
            <form className="testForm" onSubmit={props.handleSubmit} >
                <div className="col-md-12 clearfix">
                    <h5>{Capitalize(props.selectedBrand)} / Tests</h5>
                    <hr/>
                    <div className="button-add-test">
                        <button
                        type="button"
                        className="btn btn-success"
                        onClick={ props.handleAddFields } >+</button>
                    </div>
                </div>
                { props.fields.map((field, id) =>
                
                <div className="col-md-12" key={`${field}-${id}`}>
                    <div className="clearfix">
                        <div className="float-right">
                        <button
                        type="button"
                        className="btn btn-danger"
                        onClick={e => props.handleRemoveFields(id, e)} >X</button>
                        </div>
                    </div>
                    <div className="form-group row">                            
                        <label>
                        Test Name *
                        </label>
                        <input
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="testName"
                            placeholder="Test Name"
                            value={field.testName}
                        />
                    </div>
                    <div className="form-group row">                            
                        <label>
                        Description
                        </label>
                        <textarea
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="description"
                            placeholder="Description"
                            defaultValue={field.description}
                        />
                    </div>

                    <div className="form-group row">
                    
                        <label>
                        Test Domain *
                        </label>
                        <input
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="domain"
                            placeholder="Test Domain"
                            value={field.domain}
                        />
                    </div>

                    <div className="form-group row">
                    
                        <label>
                        Test Folder *
                        </label>
                        <input
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="folder"
                            placeholder="Test Folder"
                            value={field.folder}
                        />
                    </div>

                    <div className="form-group row">
                    
                        <label>
                        Tests
                        </label>
                        <textarea
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="tests"
                            placeholder="Tests"
                            defaultValue = {field.tests}
                        />
                    </div>


                    <div className="form-group-row text-left">
                        
                        <input 
                            onChange={e => props.handleChange(id, e)}
                            type="checkbox" 
                            className="" 
                            name="register"
                            checked={field.register}
                        />
                        <label>&nbsp;&nbsp;Register as normal</label>
                    </div>
                    <hr/>
                </div>
                )}
            <div className="col-md-12">
            <button
            type="button"
            className="btn btn-primary"
            onClick={ props.handleSubmit } >Save</button>
            </div>
            </form>
          </div>
          <div className="clearflex"></div>
                
        </div>
  );
}


export default TestsFormComponent