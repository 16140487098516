import React, { Component } from "react"
import { BoxLoading } from 'react-loadingg';
import { withRouter } from "react-router-dom"
import ServiceFormComponent from "./components/serviceFormComponent"
import { getLaunchConfigById, getServices, setServices, deleteService, checkLogin } from "./helpers/paraHelpers"
import { Capitalize } from "./helpers/common"

class ServiceContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: [],
            selectedBrand: '',
            formError: false,
            isLoading: true,
            isValidUser : null
        }
        this.launchData =  this.props.history.location.state
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        
    }

    async componentDidMount() {
        let isLogin = await checkLogin()
        if (!isLogin || isLogin === false) {
            this.props.history.push('/login/')
        } else {
            let launchId = this.props.match.params.id
            if (launchId) {
                let itemData = await getLaunchConfigById(launchId)
                let services = await getServices(launchId)
                if (itemData) {
                    document.title = "BCP: "+Capitalize(itemData.brandName)+':Services'
                    this.setState({
                        'selectedBrand': itemData.brandName,
                        'fields': services,
                        'isValidUser': isLogin
                    }, () => { this.setState({ isLoading: false }) })
                } else {
                    this.props.history.push('/config/')
                }
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({'fields.services': this.state.fields})
        let error = false
        for (let key in this.state.fields) {
            if(this.state.fields[key]['serviceUrl']=== ''){
                error = true
            } else if(this.state.fields[key]['redirectType']=== ''){
                error = true
            }
        }
        this.setState({ formError: error })
        if(!error){
            setServices(this.props.match.params.id, this.state.fields)
            this.props.history.goBack()
        }
        
    }

    handleChange(i, event) {
        const values = [...this.state.fields]
        const { name, value, type, checked } = event.target
        values[i][name] = value
        
        if(type === 'checkbox'){
            values[i][name] = checked
        }
        this.setState(values)
    }

    handleAddFields = () => {
        const values = [...this.state.fields]
        values.push({ serviceUrl: '', redirectType: '', path: '', healthCheckUrl: ''})
        this.setState({fields : values})
    }
    
    handleRemoveFields = index => {
        const values = [...this.state.fields]
        if (values[index]['id']) {
            deleteService(values[index]['id'])
        }        
        values.splice(index, 1)
        this.setState({ fields: values })
        console.log(values)
    }
    

    render() {
        if (this.state.isLoading) {
            return <BoxLoading color={ '#f9a74c' } size={ 'large'}/>
                
        } else {
            return <ServiceFormComponent
                handleChange={ this.handleChange }
                handleSubmit={ this.handleSubmit }
                handleAddFields={ this.handleAddFields }
                handleRemoveFields={ this.handleRemoveFields }
                { ...this.state }
                { ...this.props }/>
        }
    }
}

const ServiceRouter = withRouter(ServiceContainer)

export default ServiceRouter