import HeaderView from "../helpers/common"
import React from "react"
import config from "../config.js"

function LoginFormComponent(props){
    return (
        <div className="App">
            <HeaderView />
            <div className="App-login">
                <div className="col-md-12">
                    <button className="btn btn-success col-md-6 center" onClick={() => { login() }}>Login</button>
                </div>
            </div>
            <div className="footer-credit">Icons made by <a href="https://www.flaticon.com/authors/photo3idea-studio" title="photo3idea_studio">photo3idea_studio</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        </div>
    )
  }
  
function login(){
    window.location = "https://accounts.google.com/o/oauth2/v2/auth?" +
        "client_id=" + config.appId + "&response_type=code" +
        "&scope=openid%20email&redirect_uri=https://para.vocohub.com/google_auth" +
        "&state=" + config.accessKey + "&openid.realm=" + config.endpoint
    return false
}

export default LoginFormComponent
