/* global localStorage */
import ParaClient from "para-client-js"
import ParaObject from "para-client-js/lib/ParaObject"
import ParaPager from "para-client-js/lib/Pager"
import config from "../config.js"

const type = 'LaunchConfig'
let pc = new ParaClient(config.accessKey, null, { endpoint: config.endpoint })

export function getAllLaunchConfigs() {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        return pc.list(type, new ParaPager(null, null, null, 9999))
            .then(results => {
                return results 
            })
            .catch(function(err) {
                console.error(err)
            })
    }
}

export function getLaunchConfigById(id) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        return pc.read(type, id)
            .then(results => {
                return results
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}

export function addLaunchConfig(data) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setType(type)
        sendData.setFields(data)
        return pc.create(sendData)
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}

export function editLaunchConfig(id, data) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setId(id)
        sendData.setType(type)
        sendData.setFields(data)
        return pc.update(sendData)
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}

export function deleteLaunchConfig(id) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setId(id)
        sendData.setType(type)
        return pc.delete(sendData)
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}


export async function getLinks(parentId) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setType('link')
        sendData.setParentid(parentId)
        return pc.findTerms('link', {'parentid' : parentId, 'type': 'link'})
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}


export async function setLinks(parentId, data) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        data.forEach(elem => {
            let sendData = new ParaObject()
            sendData.setType('link')
            sendData.setFields(elem)
            sendData.setParentid(parentId)
            pc.create(sendData)
                .then(result => {
                    return result
                })
                .catch(function (err) {
                    console.error(err)
                })
        })
    }
}


export function deleteLink(id) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setId(id)
        sendData.setType('link')
        return pc.delete(sendData)
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}


export async function getServices(parentId) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setType('service')
        sendData.setParentid(parentId)
        return pc.findTerms('service', {'parentid' : parentId, 'type': 'service'})
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}

export async function setServices(parentId, data) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        data.forEach(elem => {
            let sendData = new ParaObject()
            sendData.setType('service')
            sendData.setFields(elem)
            sendData.setParentid(parentId)
            pc.create(sendData)
                .then(result => {
                    return result
                })
                .catch(function (err) {
                    console.error(err)
                })
        })
    }
}

export function deleteService(id) {
    if (localStorage.getItem('jwt')) {
        pc.setAccessToken(localStorage.getItem('jwt'))
        let sendData = new ParaObject()
        sendData.setId(id)
        sendData.setType('service')
        return pc.delete(sendData)
            .then(result => {
                return result
            })
            .catch(function (err) {
                console.error(err)
            })
    }
}

export async function getParaUser(token) {
    if (token) {   
        pc.setAccessToken(token)
        return await pc.invokeGet('/_me')
            .then(function (result) {
                return result && result.body.email ? result.body : null
            })
            .catch(function (err) {
                console.error(err)
                return false
            })
       
    } else {
        return false
    }
}

export function Logout() {
    if (localStorage.getItem('jwt')) {
        pc.signOut()
    }
    localStorage.removeItem('jwt')
    localStorage.removeItem('userData')
    let path = window.location.protocol + '//' + window.location.host + '/#login'
    window.location.assign(path)
}

export async function checkLogin() { 
    let localJwt = localStorage.getItem('jwt')
    let userData = JSON.parse(localStorage.getItem('userData'))
    let me = false
    
    let token = pc.getAccessToken(), 
        decodedLocalJwt = localJwt ? decodeToken(localJwt) : null
    
    if(decodedLocalJwt?.expired) {
        console.warn("jwt token expired, login invalid")
        localStorage.removeItem('jwt')
        localStorage.removeItem('userData')
        return false
    }

    if(
        localJwt &&
        (!token || token !== localJwt)
    ) {
        // localStorage.removeItem('jwt')
        // localJwt = null
        // userData = null

        console.warn("resetting para token")
        pc.setAccessToken(localJwt)
    
        const resp = await pc.invokeGet("/jwt_auth")

        if(resp.status === 200) {
            localStorage.setItem('jwt', resp.body.jwt.access_token)
            localStorage.setItem('userData', JSON.stringify(userData))
        }
    }

    if (localJwt === 'null' || !userData) {
        return false
    } else {
        return true
    }
}

function decodeToken(token) {
  const payloadBase64 = token.split('.')[1];
  const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
  var decoded = JSON.parse(decodedJson)
  decoded.expired = (Date.now() >= decoded.exp * 1000)
  decoded.expiresIn = (decoded.exp * 1000 - Date.now())
  return decoded
}


