/* global localStorage */
import React, { Component } from "react"
import { BoxLoading } from 'react-loadingg';
import LoginFormComponent from "./components/loginFormComponent"
import { withRouter } from "react-router-dom"
import { checkLogin, getParaUser } from "./helpers/paraHelpers"

class LoginFormContainer extends Component {

  constructor(props) {
    super(props)
    this.state = { 
        userValidated: null,
        isLoading: true
    }
    document.title = "BCP: Login"
  }

  async componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search)
    let jwt = urlParams.get('jwt')
    let userData = await getParaUser(jwt)
    if (userData) {
      localStorage.setItem('jwt', jwt)
      localStorage.setItem('userData', JSON.stringify(userData))
    }
    urlParams.delete("jwt")
    let newParams = urlParams.toString()

    if (window.history && window.history.replaceState) {
        window.history.replaceState(null, '', window.location.pathname + (newParams ? '?' + newParams : ''))
    }

    let isLogin = await checkLogin()
    if (!isLogin || isLogin === false) {
      this.props.history.push('/login/')
    } else {
      this.props.history.push('/home/')
    }
    this.setState({ isLoading: false })
  }
  
  render() {    
    if (this.state.isLoading) {
      return <BoxLoading color={ '#f9a74c' } size={ 'large' } />
    } else {
      return <LoginFormComponent />
    }
  }  
}
const LoginRouter = withRouter(LoginFormContainer)

export default LoginRouter
