import React, { Component } from "react"
import { BoxLoading } from 'react-loadingg'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {HeaderView, Capitalize, GenerateHmacUrl, GenerateTestHmacUrl, errorMessage} from "./helpers/common"
import { withRouter } from "react-router-dom"
import {getLaunchConfigById, deleteLaunchConfig, checkLogin, getLinks, Logout, getServices} from "./helpers/paraHelpers"
import _ from "lodash"
import axios from 'axios'

let pollInterval = 3000
let pollTimeout = 500
class LaunchContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            brandName: null,
            launchData: [],
            tests: [],
            services: [],
            servicesCount:null,
            testurl: '',
            isLoading: true,
            isValidUser: null,
            error: false,
            errorMessage: null,
            openedLink: false,
            isHealthy: [],
            directLaunch: this.props.location.state ? true : false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    async componentDidMount() {
        let isLogin = null
        try {
            isLogin = await checkLogin()   
        } catch (e) {
            throw(e)
        }
        if (!isLogin || isLogin === false) {
            this.props.history.push('/login/')
        } else {
            let launchId = this.props.match.params.id
            let itemData = null
            let testItems = null
            let services = null

            try {
                itemData = await getLaunchConfigById(launchId)
            } catch (e) {
                throw(e)
            }

            try {
                testItems = await getLinks(launchId)
            } catch (e) {
                throw(e)
            }
            
            try {
                services = await getServices(launchId)
            } catch (e) {
                throw(e)
            } 
            services = _.map(services, (service) => {
                service['serverStatus'] = 'unknown'
                return service
            })
            document.title = "BCP: "+Capitalize(itemData.brandName)
            this.setState({
                'launchData': itemData,
                'brandName': Capitalize(itemData.brandName),
                'tests': testItems,
                'services' : services,
                'isValidUser': isLogin
            }, () => { this.setState({ isLoading: false }) })
            if (this.state.directLaunch) {
                console.log('Launching HMAC URL directly')
                this.redirect('launch')()
            }
        }
    }


    redirect = (action, field = null) => async (e) => {
        let launchData = this.state.launchData
        if (action === 'edit'){
            this.props.history.push('/config/'+this.state.launchData.id)
        } else if (action === 'runTest') {
            this.setState({ isLoading: true })
            let testUrl = null
            try {
                testUrl = await GenerateTestHmacUrl(this.state.launchData, launchData.generateId, launchData.registrationType, field)
            } catch (e) {
                return { error: 'Something went wrong while trying to get test url' }
            } 
            this.setState({ isLoading: false })
            if (typeof testUrl !== 'string') {
                this.setState({
                    error: true,
                    errorMessage: testUrl.error
                },()=> this.state.errorMessage ? errorMessage(this.state.errorMessage): null)                
                return
            }           
            console.log('URL :', testUrl)
            window.open(testUrl, '_blank')          
        } else if (action === 'launch') {
            let responseData 
            let services = this.state.services
            let servicesCount = services.length
            this.setState({ isLoading: true })
            if (servicesCount > 0) {
                services.forEach(async (service, key) => {
                    if (service.redirectType === 'poll') {
                        responseData = await this.poll(() => {
                            return axios(service.serviceUrl, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json'
                                }
                            })
                        }, pollTimeout, pollInterval ).then(async res => {
                            this.setState({ servicesCount: --servicesCount })
                            let check = null
                            try {
                                check = await this.checkAllServiceReady(launchData, false, service)
                                if (check) {
                                    console.log('URL :', check)
                                    window.open(check, '_blank') 
                                }    
                            } catch (e) {
                                throw(e)
                            }                            
                            return res.data
                        }).catch(() => console.log('Failed to get data of url : ', service.serviceUrl))
                    } else if (service.redirectType === 'redirect') {
                        let url = _.get(responseData, service.path)
                        responseData = await this.poll(() => {
                            return axios(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json'
                                }
                            })
                        }, pollTimeout, pollInterval ).then(async res => {
                            this.setState({ servicesCount: --servicesCount })
                            let check = null
                            try {
                                check = await this.checkAllServiceReady(launchData, false, service)
                                if (check) {
                                    console.log('URL :', check)
                                    window.open(check, '_blank') 
                                }    
                            } catch (e) {
                                throw(e)
                            }       
                            return res.data
                        }).catch(() => console.log('Failed to get data of url : ', url))
    
                    }
                    if (responseData) {
                        let serviceData = this.state.services
                        console.log(responseData)
                        serviceData[key]['serverStatus'] = responseData.serverStatus
                        this.setState({'services': serviceData})
                    }
                })  
            } else {
                let check = null
                try {
                    check = await this.checkAllServiceReady(launchData, true, null)
                    if (check) {
                        console.log('URL :', check)
                        window.open(check, '_blank') 
                    }    
                } catch (e) {
                    throw(e)
                }  
            }
                      
        } else if(action === 'delete'){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='alert-delete'>
                            <h1>Are you sure?</h1>
                            <p>You want to delete this config for {this.state.brandName}</p>
                            <button onClick={onClose}>No</button>
                            <button
                                onClick={async () => {
                                    await deleteLaunchConfig(this.state.launchData.id)
                                    this.props.history.push('/home/')
                                    onClose()
                                }}
                                >
                                Yes, Delete it!
                            </button>
                        </div>
                    )
                }
            })         
        } else if(action === 'test'){
            this.props.history.push('/test/'+ this.state.launchData.id)
        } else if(action === 'service'){
            this.props.history.push('/service/'+ this.state.launchData.id)
        }
        
            
    }

    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    async checkAllServiceReady(launchData, noServices, service) {
        //Health Check
        let isHealthy = this.state.isHealthy
        if (!noServices) {
            let action = this.getParameterByName('action', service.serviceUrl)
            if (action.toUpperCase() === 'START') {
                await this.pollHealth(() => {
                    return axios(service.healthCheckUrl, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json'
                        }
                    }).catch((error) => {
                        if (!error.status) {
                            //server not ready for connection
                            return { data: { ok: false } }
                        }
                    })
                }, pollTimeout, pollInterval).then(res => {
                    isHealthy.push({ healthCheckUrl: service.healthCheckUrl, isHealthy: res })
                    console.log(service.healthCheckUrl,' : ', res)
                }).catch(() => console.log('Failed to get data of url : ', service.healthCheckUrl))
            }             
        }

        if ((this.state.servicesCount === 0 && this.state.openedLink === false) || noServices) {
            this.setState({ openedLink: true })
            let url = null
            try {
                url = await GenerateHmacUrl(this.state.launchData, launchData.generateId, launchData.registrationType)
            } catch (e) {
                return { error: 'Something went wrong while trying to get test url' }
            }
            this.setState({ isLoading: false })
            if (typeof url !== 'string') {
                this.setState({
                    error: true,
                    errorMessage: url.error
                }, () => this.state.errorMessage ? errorMessage(this.state.errorMessage) : null)
                return
            }
            if (url) {
                return url
            }
        }    
    }

    renderServices() {
        return this.state.services.map((field, id) =>
            <div className="card-body tests" key={id} >                  
                <div className={"row "+field.serverStatus }>
                    <h5 className="col-md-9">{field.serviceUrl}</h5>
                    <div className="col-md-3">{Capitalize(field.serverStatus)}</div>
                </div>
            </div>
        )   
    }

    handleChange(e) {
        const { value, name, type, checked } = e.target
        type === "checkbox"
            ? this.setState({ [name]: checked })
            : this.setState({ [name]: value })
    }

    poll(fn, timeout, interval) {
        const endTime = Number(new Date()) + (timeout || 2000)
        const checkCondition = (resolve, reject) => {
            let call = fn()
            call.then(response => {
                if (response.data !== '') {
                    resolve(response)
                }
                else if (response.data === '') {
                    setTimeout(checkCondition, interval, resolve, reject)
                }
                else if (Number(new Date()) < endTime) {
                    setTimeout(checkCondition, interval, resolve, reject)
                } else {
                    reject(new Error('time out for ' + fn + ' : ' + arguments))
                }
            })
        }
        return new Promise(checkCondition)
    
    }


    pollHealth(fn, timeout, interval) {
        const endTime = Number(new Date()) + (timeout || 2000)
        const checkCondition = (resolve, reject) => {
            let call = fn()
            call.then(response => {
                if (response.data.ok === true) {
                    resolve(response.data.ok)
                }
                else if (response.data.ok !== true) {
                    setTimeout(checkCondition, interval, resolve, reject)
                }
                else if (Number(new Date()) < endTime) {
                    setTimeout(checkCondition, interval, resolve, reject)
                } else {
                    reject(new Error('time out for ' + fn + ' : ' + arguments))
                }
            })
        }
        return new Promise(checkCondition)
    
    }
    
    render() {
        if (this.state.isLoading) {
            return <BoxLoading color={ '#f9a74c' } size={ 'large'}/>                
        } else { 
            return  <div className="App">
                <HeaderView goBack={ () => this.props.history.goBack() } onClickLogOut={ () =>  Logout() } isLogin ={this.state.isValidUser} />
                    <div className="home">
                        <div className="card card-login text-center">
                            <div className="card-header">
                                <span className="logo_title"> { this.state.brandName } </span>
                            </div>
                            <div className="card-body">                        
                                <div className="form-group input-group buttons-wrapper">
                                    <div className='launch-buttons'>
                                        <button onClick={ this.redirect('launch') } className="btn btn-success"> Launch </button>
                                    </div>
                                    <div className='launch-buttons'>
                                        <button onClick={ this.redirect('edit') } className="btn btn-success"> Edit </button>
                                    </div>
                                    <div className='launch-buttons'>
                                        <button onClick={ this.redirect('test') } className="btn btn-success"> Tests </button>
                                    </div>
                                    <div className='launch-buttons'>
                                        <button onClick={ this.redirect('service') } className="btn btn-success"> Service </button>
                                    </div>
                                    <div className='launch-buttons'>
                                        <button onClick={ this.redirect('delete') } className="btn btn-danger"> Delete </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <span className="logo_title"> Test Scripts </span>
                            </div>
                            
                            {this.state.tests.map((field, id) =>
                            <div className="card-body tests row" key={id} >  
                                <div className="col-md-10">                      
                                    <h5>{field.testName}</h5>
                                    <div className="testDescription">{field.description}</div>
                                </div>
                                <div className='col-md-2 clearfix'>
                                    <button onClick={ this.redirect('runTest', field) } className="btn btn-primary float-right"> > </button>
                                </div>
                            </div>
                            )}
                            
                            <div className="card-header">
                                <span className="logo_title"> Services </span>
                            </div>
                            
                            {this.renderServices()}
                            
                        </div>
                    </div>
                    <div className="clearflex"></div>
                </div>
        }
        
    }
}

const LaunchRouter = withRouter(LaunchContainer)

export default LaunchRouter