import React, { Component } from "react"
import { BoxLoading } from 'react-loadingg';
import BrandFormComponent from "./components/brandFormComponent"
import { withRouter } from "react-router-dom"
import { getLaunchConfigById, addLaunchConfig, editLaunchConfig, checkLogin } from "./helpers/paraHelpers"
import _ from 'lodash'
import { Capitalize } from "./helpers/common"

class BrandFormContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      brandName: '',
      registrationType: '',
      // email: '',
      // externalId: '',
      generateId: '',
      list: '',
      // pattren: '',
      environment: '',
      region: '',
      appName: '',
      parameters: '',
      hmacKey: '',
      overrideURL: '',
      hmacParameters: [],
      selectedOption: [],
      tests: [],
      formError: false,
      isLoading: true,
      isValidUser: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  async componentDidMount() {
    let isLogin = await checkLogin()
    if (!isLogin || isLogin === false) {
      this.props.history.push('/login/')
    } else {  
      this.setState({ 'isValidUser': isLogin })
      let launchId = this.props.match.params.id
      if (launchId) {
        let itemData = await getLaunchConfigById(launchId)
        if (itemData) {
            document.title = "BCP: "+Capitalize(itemData.brandName)+':Config'
          _.map(itemData.parameters.split('\n'), (value) => {
            let fieldToset = value
            let fieldValue = itemData[value]
            this.setState({ [fieldToset]: fieldValue })
          })
          this.setState({
            'brandName': itemData.brandName,
            'registrationType': itemData.registrationType,
            // 'email': itemData.email,
            // 'externalId': itemData.externalId,
            'list': itemData.list,
            // 'pattren': itemData.pattren,
            'environment': itemData.environment,
            'generateId': itemData.generateId,
            'region': itemData.region,
            'appName': itemData.appName,
            'parameters': itemData.parameters,
            'hmacParameters': itemData.hmacParameters,
            'selectedOption': itemData.selectedOption,
            'overrideURL' : itemData.overrideURL || "",
            'tests': itemData.tests,
            'hmacKey': itemData.hmacKey,
            'hmacSignatureParameter' : itemData.hmacSignatureParameter,
            'hmacNonceParameter' : itemData.hmacNonceParameter
          }, () => { this.setState({ isLoading: false }) })

        } else {
          this.props.history.push('/config/')
        }
      } else {
        this.setState({ isLoading: false })
      }
    }
}

  handleChange(e) {
    const { value, name, type, checked } = e.target;
    type === "checkbox"
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value })
    
    if(name === 'parameters'){
      let newSelected = []
      let previousSelected = this.state.selectedOption
      let optionsAvailable = (value).split('\n').map(function(val){
        return val ? { 'value':val, 'label':val } : ''
      }).filter(v => v.value && v.value !== '')
       
      previousSelected.forEach(function(value){
        for(var i = 0; i < optionsAvailable.length; i++) {
          if(optionsAvailable[i].value === value.value){
            newSelected.push(value)
          }
        }
      })
      this.setState({ 'selectedOption' : newSelected})
    }

  }

  async handleSubmit(e) {
    e.preventDefault()
    this.setState({ 'isLoading' : true})
    if(!this.state.brandName || !this.state.registrationType){
      this.setState({ 'formError' : true})
      return
    }
    this.setState({ 'formError': false })    
    // if (this.state.registrationType === 'externalId') {
    //   this.setState({ 'email': '' })
    // }

    let postData = this.state
    if (!this.props.match.params.id) {
      let configId = await addLaunchConfig(postData)
      if(configId){
        this.props.history.push('/home/')
      }
    } else {
      let edited = await editLaunchConfig(this.props.match.params.id, postData)
      if (edited) {
        this.setState({ 'isLoading': false })
        this.props.history.push('/launch/'+edited.id) 
      }
    }    
  }

  handleSelectChange = selectedOption => {
    this.setState({'selectedOption': selectedOption})
    this.setState({'hmacParameters': selectedOption})
  }

  render() {
    if (this.state.isLoading) {
      return <BoxLoading color={ '#f9a74c' } size={ 'large'}/>
    } else {
      return  <BrandFormComponent
              handleChange={this.handleChange} 
              handleSubmit={this.handleSubmit} 
              handleSelectChange={this.handleSelectChange}
              {...this.state}
              {...this.props}
              />
    }
    
  }
}
const ConfigRouter = withRouter(BrandFormContainer)

export default ConfigRouter
