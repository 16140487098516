import React, { Component } from "react"
import { BoxLoading } from "react-loadingg"
import HeaderView from "./helpers/common"
import { getAllLaunchConfigs, checkLogin, Logout } from "./helpers/paraHelpers"
import { CreateLaunchConfigList } from "./helpers/common"
import { withRouter } from "react-router-dom"
import { FaPlus } from 'react-icons/fa';
import './homeContainer.css'

class HomeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            brandId: null,
            itemsToList: null,
            isLoading: true,
            isValidUser: null
        }
        document.title = "BCP: Home"
        this.handleChange = this.handleChange.bind(this)
        
    }

    async componentDidMount() {
        let isLogin = await checkLogin()
        if (!isLogin || isLogin === false) {
            this.props.history.push('/login/')
        } else {
            let items = await getAllLaunchConfigs()
            if(items){
                this.setState({'itemsToList' : items, 'isValidUser': isLogin}, () => { this.setState( {isLoading : false})})
            }
        }
    }

    redirect = (to) => {
        if((to === 'launch')){
            this.props.history.push('/launch/'+this.state.brandId)            
        } else if((to === 'config')) {
            this.props.history.push('/config/')
        }
            
    }

    handleChange(e) {
        const { value, name, type, checked } = e.target;
        type === "checkbox"
            ? this.setState({ [name]: checked })
            : this.setState({ [name]: value })
    }

    LaunchConfigList = ({ list }) => (
    <ul>
        {list.map(item => (
        <li key={item}>{item}</li>
        ))}
    </ul>
    )

    render() {
        if (this.state.isLoading) {
            return <BoxLoading color={ '#f9a74c' } size={ 'large'}/>
        } else {
            return <div className="App">
                <HeaderView onClickLogOut={ () =>  Logout() } isLogin ={this.state.isValidUser}/>
                <div className="home">
                    <div className="card card-login text-center">
                        <div className="card-header">
                            <span className="logo_title"> Brand Control Panel </span>
                            <span onClick={ () => { this.redirect('config') } } className="btn btn-success addBrand"> <FaPlus/></span>
                        </div>
                        <div className="card-body">
                            <div className="input-group form-group brandList">
                                {
                                    this.state.itemsToList ? <CreateLaunchConfigList list={this.state.itemsToList} /> : null 
                                }   
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="clearflex"></div>
            </div>
        }
    }
}

const HomeRouter = withRouter(HomeContainer)

export default HomeRouter