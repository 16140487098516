import React from "react"
import { GetErrorMessage, HeaderView, Capitalize } from '../helpers/common'
import { Logout } from '../helpers/paraHelpers'

function ServiceFormComponent(props) {
  return (
    <div className="App">
        <HeaderView goBack={ () => props.history.goBack() } onClickLogOut={ () =>  Logout() } isLogin ={props.isValidUser}/>
        <div className="container">   
            {(props.formError) ? <GetErrorMessage {...props} /> : '' }            
            <form className="testForm" onSubmit={props.handleSubmit} >
                <div className="col-md-12 clearfix">
                    <h5>{Capitalize(props.selectedBrand)} / Services</h5>
                    <hr/>
                    <div className="button-add-test">
                        <button
                        type="button"
                        className="btn btn-success"
                        onClick={ props.handleAddFields } >+</button>
                    </div>
                </div>
                { props.fields.map((field, id) =>
                
                <div className="col-md-12" key={`${field}-${id}`}>
                    <div className="clearfix">
                        <div className="float-right">
                        <button
                        type="button"
                        className="btn btn-danger"
                        onClick={e => props.handleRemoveFields(id, e)} >X</button>
                        </div>
                    </div>
                    <div className="form-group row">                            
                        <label>
                        Service URL *
                        </label>
                        <input
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="serviceUrl"
                            placeholder="Service URL"
                            value={field.serviceUrl}
                        />
                    </div>
                    <div className="form-group row">                            
                        <label>
                            Redirect Type *
                        </label>
                        <select
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="redirectType"
                            value={field.redirectType}
                        >
                            <option value="">-- Please Choose Redirect Type--</option>
                            <option value="redirect"> Redirect </option>
                            <option value="poll"> Poll </option>
                        </select>
                        </div>
                        <div className="form-group row">                            
                            <label>
                            Path
                            </label>
                            <input
                                onChange={e => props.handleChange(id, e)}
                                className="form-control"
                                name="path"
                                placeholder="Path"
                                value={field.path}
                            />
                        </div>

                        <div className="form-group row">                            
                        <label>
                        Health Check URL *
                        </label>
                        <input
                            onChange={e => props.handleChange(id, e)}
                            className="form-control"
                            name="healthCheckUrl"
                            placeholder="Health Check URL"
                            value={field.healthCheckUrl}
                        />
                        </div>
                        
                    <hr/>
                </div>
                )}
            <div className="col-md-12">
            <button
                type="button"
                className="btn btn-primary"
                onClick={ props.handleSubmit } >Save</button>
            </div>
            </form>
          </div>
          <div className="clearflex"></div>
        </div>
  );
}


export default ServiceFormComponent